import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Swal from 'sweetalert2';
import * as Model from '../../../Common/Model';
import * as request from '../../../Common/Util/Request';
import * as Button from '../../../Common/Component/Button';
import * as Field from '../../../Common/Component/Field';
import './index.scss';
import { LECTURE } from '../../../Common/Constant';
import dayjs from 'dayjs';
import { Autocomplete, CircularProgress, TextField, Tooltip } from '@mui/material';

function Single(props) {
  const intl = useIntl();
  const history = useHistory();
  const { pathname, state } = useLocation();
  const { id } = useParams();

  const [lectureOrigin, setLectureOrigin] = useState(null);
  const editing = useMemo(() => !!id && !!lectureOrigin?.id, [id, lectureOrigin]);
  const lectureType = useMemo(() => pathname.split('/')[2], [pathname]);
  const [host, setHost] = useState('ours');
  const [type, setType] = useState('online');
  const [lectureList, setLectureList] = useState([]);
  const hostOptions = useMemo(() => {
    return [
      { value: 'ours', label: '자사' },
      { value: 'gov', label: '정부' },
    ];
  }, []);
  const typeOptions = useMemo(() => {
    let options = [
      { value: 'online', label: '온라인(일반)', order: 1 },
      { value: 'foreigner', label: '외국인 대상', order: 5 },
    ];
    if (lectureType === 'single') {
      options.push(
        { value: 'roundOnline', label: '온라인(기수제)', order: 2 },
        { value: 'offline', label: '오프라인', order: 3 },
        { value: 'consulting', label: '컨설팅', order: 4 },
        { value: 'compulsory', label: '법정의무교육', order: 5 },
      );
    }

    if (host === 'gov') {
      options = [
        { value: 'online', label: '온라인(일반)', order: 1 },
        { value: 'offline', label: '오프라인', order: 3 },
      ];
    }

    options.sort((a, b) => a.order - b.order);

    return options;
  }, [lectureType, host]);

  const fetchLectureList = useCallback(() => {
    const params = {
      page: 1,
      limit: 999_999_999,
      filtered: JSON.stringify([]),
    };
    request.simpleLectures(lectureType, params).then(({ classes }) => {
      setLectureList(classes.rows);
    });
  }, []);

  useEffect(() => {
    if (id) {
      request.lectureDetail(lectureType, id).then((data) => {
        setLectureOrigin(new Model.Lecture(data));
      });
    } else {
      fetchLectureList();
    }
  }, [state, fetchLectureList]);

  const [open, setOpen] = useState(false);
  const loading = useMemo(() => open && lectureList.length === 0, [open, lectureList]);
  const [copyLectureTargetId, setCopyLectureTargetId] = useState(null);

  const initTerms = useMemo(
    () => [
      {
        tempId: dayjs().valueOf() + Math.random(),
        title: '강의 및 관련 안내 수신',
        content: `<p style="text-align: center;">수강안내, 교육이수증 발급안내, 공모전 안내 등<br>교육과 관련된 정보 및 안내 사항에 대한<br>이메일, 문자를 수신하는 것에 동의합니다.</p>`,
      },
      {
        tempId: dayjs().valueOf() + Math.random(),
        title: '개인정보 제 3자 제공동의',
        content: `<p style="text-align: center;">교육 운영을 위한 제 3자 개인정보 제공에 동의합니다.</p>
          <p style="text-align: center;">&nbsp;</p>
          <table style="border-collapse: collapse; width: 100%; border-width: 1px; height: 189.578px; border-style: solid; margin-left: auto; margin-right: auto;" border="1"><colgroup><col style="width: 39.8004%;"><col style="width: 60.1996%;"></colgroup>
          <tbody>
          <tr style="height: 36.1953px;">
          <td style="border-style: solid; border-width: 1px; background-color: #ced4d9;"><strong>개인정보를 제공 받는 자</strong></td>
          <td style="border-style: solid; border-width: 1px;">엔터잡에듀</td>
          </tr>
          <tr style="height: 58.5938px;">
          <td style="border-style: solid; border-width: 1px; background-color: #ced4d9;"><strong>개인정보를 제공 받는 자의 개인정보 이용 목적</strong></td>
          <td style="border-style: solid; border-width: 1px;">교육 운영 (지원자 통계, 교육대상자 선발, 수료증 발급 등)</td>
          </tr>
          <tr style="height: 36.1953px;">
          <td style="border-style: solid; border-width: 1px; background-color: #ced4d9;"><strong>제공하는 개인정보의 항목</strong></td>
          <td style="border-style: solid; border-width: 1px;">이름, 주민등록번호, 이메일, 휴대폰, 주소, 학교명</td>
          </tr>
          <tr style="height: 58.5938px;">
          <td style="border-style: solid; border-width: 1px; background-color: #ced4d9;"><strong>개인정보를 제공받는 자의 개인정보 보유 및 이용 기간</strong></td>
          <td style="border-style: solid; border-width: 1px;">수집 후 1년</td>
          </tr>
          </tbody>
          </table>`,
      },
    ],
    [],
  );

  const localStorageLecture = JSON.parse(localStorage.getItem('lectureLocalData'));
  const [isBlocked, setIsBlocked] = useState(Boolean(id));

  const [title, setTitle] = useState(null);
  const [onSale, setOnSale] = useState(false);
  const usesUSD = type === 'foreigner';
  const [tutorIds, setTutorIds] = useState([]);
  const [selectedTutors, setSelectedTutors] = useState([]);
  const [tutorRates, setTutorRates] = useState([]);
  const [categoryIds, setCategoryIds] = useState({
    company: [],
    field: [],
    process: [],
  });
  const [image, setImage] = useState(null);
  const [originalPrice, setOriginalPrice] = useState(null);
  const [discountStartDate, setDiscountStartDate] = useState(null);
  const [discountStartTime, setDiscountStartTime] = useState(null);
  const [discountEndDate, setDiscountEndDate] = useState(null);
  const [discountEndTime, setDiscountEndTime] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [registrationText, setRegistrationText] = useState('입력한 정보로 지원서가 제출됩니다.');

  const [onlineOption, setOnlineOption] = useState({});
  const [roundOnlineOptions, setRoundOnlineOptions] = useState([]);
  const [offlineOptions, setOfflineOptions] = useState([]);
  const [consultingOptions, setConsultingOptions] = useState([]);
  const [deleteOptionList, setDeleteOptionList] = useState([]);

  const [material, setMaterial] = useState(null);
  const [certProgressLimit, setCertProgressLimit] = useState(null);
  const [description, setDescription] = useState(null);
  const [introduction, setIntroduction] = useState(null);

  const [samples, setSamples] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [transferReviewLogs, setTransferReviewLogs] = useState([]);
  const [transferReviewsFromLecture, setTransferReviewsFromLecture] = useState([]);
  const [terms, setTerms] = useState(initTerms);

  const [showTimer, setShowTimer] = useState(false);
  const [timerText, setTimerText] = useState('');

  const [additive, setAdditive] = useState(0);
  const [externalLink, setExternalLink] = useState(null);
  const [tutorCompanyText, setTutorCompanyText] = useState(null);
  const [autoCertificate, setAutoCertificate] = useState(true);
  const [thirdPartyQuestionFields, setThirdPartyQuestionFields] = useState([]);
  const [useSurveyGuide, setUseSurveyGuide] = useState(false);

  const discountPercent = useMemo(
    () => (1 - discountedPrice / originalPrice) * 100,
    [usesUSD, originalPrice, discountedPrice],
  );

  const exposeCompanyTutors = useMemo(() => {
    return selectedTutors.filter((tutor) => tutor?.is_company_expose);
  }, [selectedTutors]);

  useEffect(() => {
    if (lectureOrigin) {
      setTitle(lectureOrigin.title || null);
      setOnSale(lectureOrigin.is_selling || false);
      setHost(lectureOrigin.host || 'ours');
      setType(lectureOrigin.listen_type || 'online');
      setTutorCompanyText(lectureOrigin.tutor_company_text || null);
      setTutorIds(lectureOrigin.tutorIds || []);
      setTutorRates(
        lectureOrigin.tutorRates ? lectureOrigin.tutorRates : Array(lectureOrigin.tutorIds?.length || []).fill(0),
      );
      setCategoryIds(
        lectureOrigin.categoryIds || {
          company: [],
          field: [],
          process: [],
        },
      );
      setImage(lectureOrigin.image_thumb || null);
      setOriginalPrice(lectureOrigin.price_original || null);
      setDiscountedPrice(
        Math.round(lectureOrigin.price_original - lectureOrigin.price_original * (lectureOrigin.discount_rate / 100)) ||
          null,
      );
      if (lectureOrigin.discount_start) {
        setDiscountStartDate(new Date(lectureOrigin.discount_start));
      }
      if (lectureOrigin.discount_start_time) {
        setDiscountStartTime(lectureOrigin.discount_start_time);
      }
      if (lectureOrigin.discount_end) {
        setDiscountEndDate(new Date(lectureOrigin.discount_end));
      }
      if (lectureOrigin.discount_end_time) {
        setDiscountEndTime(lectureOrigin.discount_end_time);
      }
      setOnlineOption(lectureOrigin.onlineOption || {});
      const modifyOriginOptions = (option) => {
        return {
          ...option,
          show_start_date: option.show_start_datetime
            ? moment(moment.utc(option.show_start_datetime).format('YYYY-MM-DD')).toDate()
            : null,
          show_start_time: option.show_start_datetime ? moment.utc(option.show_start_datetime).format('HH:mm') : null,
          show_end_date: option.show_end_datetime
            ? moment(moment.utc(option.show_end_datetime).format('YYYY-MM-DD')).toDate()
            : null,
          show_end_time: option.show_end_datetime ? moment.utc(option.show_end_datetime).format('HH:mm') : null,
        };
      };
      setOfflineOptions(
        lectureOrigin.offlineOptions?.length > 0
          ? lectureOrigin.offlineOptions.map((offlineOption) => modifyOriginOptions(offlineOption))
          : [],
      );
      setConsultingOptions(
        lectureOrigin.consultingOptions?.length > 0
          ? lectureOrigin.consultingOptions.map((consultingOption) => modifyOriginOptions(consultingOption))
          : [],
      );
      setRoundOnlineOptions(
        lectureOrigin.roundOnlineOptions?.length > 0
          ? lectureOrigin.roundOnlineOptions.map((roundOnlineOption) => modifyOriginOptions(roundOnlineOption))
          : [],
      );
      setMaterial(lectureOrigin.url_attached_file || null);
      setCertProgressLimit(lectureOrigin.pass_rate || null);
      setIntroduction(lectureOrigin.class_introduction || null);
      setDescription(lectureOrigin.description || null);
      setSamples(
        lectureOrigin.ClassSampleVideos?.map((ClassSampleVideo) => ({
          ...ClassSampleVideo,
          video_length: moment
            .utc(moment.duration(ClassSampleVideo.video_length, 's').asMilliseconds())
            .format(ClassSampleVideo.video_length >= 3600 ? 'h:mm:ss' : 'mm:ss'),
        })) || [],
      );
      setChapters(
        lectureOrigin.ClassChapters?.map((ClassChapter, idx) => ({
          ...ClassChapter,
          type: ClassChapter.ClassSections[0]?.FK_survey_id ? 'survey' : 'lecture',
          timing: ClassChapter.ClassSections[0]?.FK_survey_id
            ? ClassChapter.ClassSections[0].FK_survey?.Class_Surveys[0]?.timing
            : null,
          ClassSections: ClassChapter.ClassSections?.map((ClassSection) => ({
            ...ClassSection,
            type: ClassSection.FK_survey_id ? 'survey' : 'lecture',
          })),
        })) || [],
      );
      setLectures(lectureOrigin.childLectures || []);
      setTimerText(lectureOrigin.timerText || '');
      setShowTimer(lectureOrigin.showTimer || false);
      setTransferReviewLogs(lectureOrigin.TransferReview_Logs || []);
      setAdditive(lectureOrigin.additive || 0);
      setTerms(lectureOrigin.ClassTerms || initTerms);
      setRegistrationText(lectureOrigin.registration_text || null);
      setExternalLink(lectureOrigin.external_link || null);
      setAutoCertificate(lectureOrigin.auto_certificate ?? true);
      setThirdPartyQuestionFields(lectureOrigin.thirdPartyQuestionFields || []);
      setUseSurveyGuide(lectureOrigin.use_survey_guide || false);
    }
  }, [lectureOrigin]);

  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  const beforeCompleteExit = () => {
    if (window.opener) {
      window.opener.location.reload();
    }
    localStorage.removeItem('lectureLocalData');
  };

  const onClickSave = useCallback(() => {
    const lecture = {
      class_type: lectureType,
      host,
      listen_type: type,
      title: title,
      is_selling: onSale,
      tutor_company_text: exposeCompanyTutors.length >= 2 ? tutorCompanyText : null,
      tutor_ids: tutorIds.join(','),
      tutor_rates: tutorRates.join(','),
      category_info: JSON.stringify(categoryIds),
      price_original: Number(originalPrice),
      discount_rate: Number(discountPercent),
      discount_start: discountStartDate && moment(discountStartDate).format('YYYY-MM-DD'),
      discount_start_time: discountStartTime,
      discount_end: discountEndDate && moment(discountEndDate).format('YYYY-MM-DD'),
      discount_end_time: discountEndTime,
      pass_rate: Number(certProgressLimit),
      class_introduction: introduction,
      attached_file: material,
      class_thumb_image: image,
      description: description,
      transfer_reviews_from_ids: transferReviewsFromLecture.map((lecture) => lecture.id).join(','),
      showTimer,
      timerText,
      additive,
      external_link: externalLink,
      terms: JSON.stringify(terms),
      registration_text: registrationText,
      auto_certificate: autoCertificate,
      thirdPartyQuestionFields: JSON.stringify(thirdPartyQuestionFields),
      use_survey_guide: useSurveyGuide,
    };

    /** 강의 타입별 옵션 설정 */
    if (!LECTURE.ROUND_LISTEN_TYPES.includes(type) || lectureType === 'package') {
      if (onlineOption.valid_days) {
        const { valid_days, start_date, student_limit } = onlineOption;
        lecture.onlineOptions = JSON.stringify({
          valid_days: Number(valid_days),
          start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : undefined,
          student_limit: student_limit || 0,
        });
      }
    } else {
      const offlineOrConsultingOptions = type === 'offline' ? offlineOptions : consultingOptions;

      if (roundOnlineOptions.length > 0) {
        lecture.roundOnlineOptions = JSON.stringify(
          roundOnlineOptions.map((option) => ({
            ...option,
            start_date: option.start_date ? moment(option.start_date).format('YYYY-MM-DD') : option.start_date,
            end_date: option.end_date ? moment(option.end_date).format('YYYY-MM-DD') : option.end_date,
            class_round: option.class_round ? Number(option.class_round) : option.class_round,
            student_limit: option.student_limit ? Number(option.student_limit) : option.student_limit,
            option_price: option.option_price ? Number(option.option_price) : option.option_price,
            show_start_datetime: moment(
              `${moment(option.show_start_date).format('YYYY-MM-DD')} ${option.show_start_time}`,
            ).format('YYYY-MM-DD HH:mm:ss'),
            show_end_datetime: moment(
              `${moment(option.show_end_date).format('YYYY-MM-DD')} ${option.show_end_time}`,
            ).format('YYYY-MM-DD HH:mm:ss'),
          })),
        );
      } else if (offlineOrConsultingOptions.length > 0) {
        lecture[`${type === 'consulting' ? 'consultingOptions' : 'offlineOptions'}`] = JSON.stringify(
          offlineOrConsultingOptions.map((option) => ({
            ...option,
            start_date: moment(option.start_date).format('YYYY-MM-DD'),
            end_date: moment(option.end_date).format('YYYY-MM-DD'),
            class_round: Number(option.class_round),
            option_price: Number(option.option_price),
            show_start_datetime: option.show_start_date
              ? moment(`${moment(option.show_start_date).format('YYYY-MM-DD')} ${option.show_start_time}`).format(
                  'YYYY-MM-DD HH:mm:ss',
                )
              : null,
            show_end_datetime: option.show_end_date
              ? moment(`${moment(option.show_end_date).format('YYYY-MM-DD')} ${option.show_end_time}`).format(
                  'YYYY-MM-DD HH:mm:ss',
                )
              : null,
          })),
        );
      }

      if (deleteOptionList.length > 0) {
        lecture.deleteOptionList = JSON.stringify(deleteOptionList);
      }
    }

    /** 샘플 영상 */
    if (samples.length > 0) {
      let samplesArr = samples.map((sample) => ({
        title: sample.title,
        url: sample.url,
        video_length: moment.duration((sample.video_length.length > 5 ? '' : '00:') + sample.video_length).asSeconds(),
      }));
      samplesArr = samplesArr.filter((sample) => {
        if (sample.title !== '' || sample.url !== '' || sample.video_length !== 0) return true;
      });
      lecture.samples = JSON.stringify(samplesArr);
    }

    /** 강의 구성 */
    if (lectureType === 'single') {
      if (chapters.length > 0) {
        // 강의 챕터 설정
        lecture.chapters = JSON.stringify(
          chapters.map((chapter) => {
            const isSurvey = chapter.type === 'survey';

            const chaptersObj = {
              title: chapter.title,
              sections: chapter.ClassSections.map((ClassSection) => {
                const sectionObj = {
                  title: ClassSection.title,
                  image_thumb: ClassSection.image_thumb,
                  ...(type !== 'offline' &&
                    !isSurvey && {
                      video_id: ClassSection.FK_video_id,
                    }),
                  ...(isSurvey && {
                    survey_id: ClassSection.FK_survey_id,
                  }),
                };
                if (ClassSection.id) sectionObj['id'] = ClassSection.id;
                return sectionObj;
              }),
              ...(isSurvey && {
                type: chapter.type,
                timing: chapter.timing,
              }),
            };
            if (chapter.id) chaptersObj['id'] = chapter.id;
            return chaptersObj;
          }),
        );
        lecture.section_thumb_images = chapters
          .map((chapter) => chapter.ClassSections)
          .reduce((acc, cur) => [].concat(acc, cur), [])
          .map((section) => section.image_thumb);
      }
    } else {
      lecture.class_ids = lectures
        .map((lecture) => (lecture.FK_class_single ? lecture.FK_class_single.id : lecture.id))
        .join(',');
    }

    /** 필수 입력값 체크 */
    const requiredFields = ['title', 'tutor_ids', 'class_thumb_image', 'price_original'];
    const emptyFields = requiredFields.filter((field) => !lecture[field] && lecture[field] !== 0);
    if (emptyFields.length > 0) {
      Swal.fire({
        html: `${emptyFields
          .map((field) =>
            intl.formatMessage({
              id: 'ID_LECTURE_FIELD_' + field.toUpperCase(),
            }),
          )
          .join(', ')} 값을 입력해주세요.`,
        toast: true,
        position: 'center',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: true,
        icon: 'error',
      });
      return;
    }

    let isValidSurvey = true;
    chapters.forEach((chapter) =>
      chapter.ClassSections.forEach((ClassSection) => {
        if (ClassSection.type === 'survey') {
          if (!ClassSection.FK_survey_id) {
            isValidSurvey = false;
          }
        }
      }),
    );
    if (!isValidSurvey) {
      Swal.fire({
        html: `설문조사의 설문값을 입력해주세요.`,
        toast: true,
        position: 'center',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: true,
        icon: 'error',
      });
      return;
    }
    if (!!(discountedPrice % 100) && !usesUSD) {
      Swal.fire({
        html: `판매가를 100원 단위로 입력해주세요.`,
        position: 'center',
        showConfirmButton: true,
        icon: 'error',
      });
      return;
    }
    if (discountPercent < 0) {
      Swal.fire({
        html: `할인율은 0% 미만일 수 없습니다.`,
        position: 'center',
        showConfirmButton: true,
        icon: 'error',
      });
      return;
    }
    if (type === 'compulsory' && (!onlineOption.valid_days || !onlineOption.start_date)) {
      Swal.fire({
        html: `법정의무교육 강의는 '수강일수'와 '수강시작일'을 입력해야 합니다.`,
        position: 'center',
        showConfirmButton: true,
        icon: 'error',
      });
      return;
    }

    lecture.showTimer = showTimer;
    lecture.timerText = timerText;

    // console.log(additive, lecture);
    if (editing) {
      const sendMessages = (waitingUsers) => {
        const transmitInfos = [];
        const buttons = [
          {
            name: '수강 신청 하러가기',
            linkMobile: `https://enterjobedu.co.kr/lecture/${id}`,
            linkPc: `https://enterjobedu.co.kr/lecture/${id}`,
            type: 'WL',
          },
          {
            name: '문의하기',
            linkMobile: 'https://enterjobedu.channel.io/',
            linkPc: 'https://enterjobedu.channel.io/',
            type: 'WL',
          },
        ];

        for (const waitingUser of waitingUsers) {
          if (waitingUser.isKo) {
            transmitInfos.push({
              template_code: 'EJE0009',
              text: `안녕하세요 ${waitingUser.name}님\n엔터잡에듀입니다.\n\n대기 신청하신 [${title}]이 오픈되었습니다.\n\n아래 링크를 통해 수강 신청이 가능하오니 일정 확인 후 신청해주시기 바랍니다.\n\n기타 문의사항은 아래 링크를 통해 연락주시면, 성심성의껏 답변드리겠습니다.\n\n감사합니다.`,
              phone: waitingUser.phone,
              purpose: 'waiting',
              lectureId: id,
            });
          } else {
            transmitInfos.push({
              template_sid: 8588,
              email: waitingUser.email,
              purpose: 'waiting',
              parameters: {
                lectureTitle: title,
                userName: waitingUser.name,
                lectureAddress: `https://enterjobedu.co.kr/lecture/${id}`,
              },
              lectureId: id,
            });
          }
        }

        // 알림 전송
        request
          .sendPersonalMessages({
            transmitInfos,
            buttons,
          })
          .then((res) => {
            beforeCompleteExit();

            if (res.success) {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">수강 대기 신청자(들)에게 알림이 전송되었습니다.</div>`,
                position: 'center',
                icon: 'success',
              }).then(() => {
                window.exitFlag = true;
                window.self.close();
              });
            } else {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">${
                  res.failList.length
                }명의 수강 대기 신청자(들)에게 알림 전송 요청이 실패하였습니다. <br>아래는 해당 회원들의 주소로, 잘못된 주소일 수 있습니다.
                  <br><br>
                  <ul>
                    ${res.failList.map((item) => `<li>${item}</li>`).join('')}
                  </ul></div>`,
                position: 'center',
                icon: 'error',
              }).then(() => {
                window.exitFlag = true;
                window.self.close();
              });
            }
          });
      };

      const updateLecture = () => {
        request.updateLecture(lectureOrigin.id, lecture, lectureType).then((res) => {
          if (res.waitingInfos?.isCanSendMessages && !!res.waitingInfos.waitingUsers.length) {
            // 수강 대기자들에게 알림 전송할 것인가?
            Swal.fire({
              html: `<div style="margin-top:20px;">
        <p style="font-weight: bold;">수정 사항이 반영되었습니다.</p>
        <p>
          <span style="font-weight: bold; color: red">${res.waitingInfos.waitingUsers.length}명</span>의 수강 대기
          신청자가 있습니다.
        </p>
        <p style="font-weight: bold;">
          수강 대기 신청자${res.waitingInfos.waitingUsers.length > 1 ? '(들)' : ''}에게
          <span style="color: red">오픈</span> 알림을 전송하시겠습니까?
        </p>
      </div>`,
              position: 'center',
              showConfirmButton: true,
              confirmButtonText: '⚠️ 알림 전송',
              showCancelButton: true,
              icon: 'success',
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                sendMessages(res.waitingInfos.waitingUsers);
              } else {
                beforeCompleteExit();

                window.exitFlag = true;
                window.self.close();
              }
            });
          } else {
            beforeCompleteExit();

            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">수정 사항이 반영되었습니다.</div>`,
              position: 'center',
              icon: 'success',
            }).then(() => {
              // setIsBlocked(false);
              window.exitFlag = true;
              window.self.close();
            });
          }
        });
      };

      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">수정 사항을 반영하시겠습니까?</div>`,
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
      }).then(({ isConfirmed }) => {
        if (isConfirmed && (lecture['price_original'] === 0 || lecture['discount_rate'] === 100)) {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">해당 강의의 가격이 무료가 맞습니까?</div>`,
            position: 'center',
            showConfirmButton: true,
            showCancelButton: true,
            icon: 'warning',
            preConfirm: () => updateLecture(),
          });
        } else if (isConfirmed) updateLecture();
      });
    } else {
      // 강의 생성
      const createLecture = () => {
        request.createLecture(lecture).then(() => {
          beforeCompleteExit();
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">신규 강의가 생성되었습니다.</div>`,
            position: 'center',
            icon: 'success',
          }).then(() => {
            window.exitFlag = true;
            window.self.close();
          });
        });
      };

      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">강의를 생성하시겠습니까?</div>`,
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
      }).then(({ isConfirmed }) => {
        if (isConfirmed && (lecture['price_original'] === 0 || lecture['discount_rate'] === 100)) {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">무료 강의를 생성하시는 게 맞습니까?</div>`,
            position: 'center',
            showConfirmButton: true,
            showCancelButton: true,
            icon: 'warning',
            preConfirm: () => createLecture(),
          });
        } else if (isConfirmed) createLecture();
      });
    }
  }, [
    intl,
    history,
    editing,
    lectureOrigin,
    lectureType,
    title,
    onSale,
    host,
    type,
    tutorCompanyText,
    tutorIds,
    tutorRates,
    categoryIds,
    image,
    originalPrice,
    discountPercent,
    deleteOptionList,
    discountStartDate,
    discountStartTime,
    discountEndDate,
    discountEndTime,
    onlineOption,
    roundOnlineOptions,
    consultingOptions,
    offlineOptions,
    material,
    certProgressLimit,
    introduction,
    samples,
    chapters,
    lectures,
    description,
    transferReviewsFromLecture,
    showTimer,
    timerText,
    additive,
    selectedTutors,
    terms,
    registrationText,
    externalLink,
    autoCertificate,
    thirdPartyQuestionFields,
    useSurveyGuide,
  ]);

  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">강의를 저장하지 않고 나가시겠습니까?</div>`,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  });

  const onClickRemove = useCallback(() => {
    {
      Swal.fire({
        title: '정말 삭제하시겠습니까?',
        text: '삭제한 데이터는 복구할 수 없습니다.',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: '삭제',
        preConfirm: () =>
          request
            .deleteLecture(lectureOrigin.id)
            .then(({ success }) => {
              if (success) {
                window.exitFlag = true;
                window.opener.location.reload();
                Swal.fire({
                  html: `<div style="margin-top:20px; font-weight: bold;">강의가 삭제되었습니다.</div>`,
                  toast: true,
                  position: 'center',
                  timer: 4000,
                  timerProgressBar: true,
                  showConfirmButton: true,
                  icon: 'success',
                  type: 'success',
                }).then(() => window.self.close());
              } else {
                throw new Error('삭제에 실패하였습니다.');
              }
            })
            .catch(() => {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">삭제에 실패했습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
                icon: 'error',
                type: 'error',
              });
            }),
      });
    }
  });

  useEffect(() => {
    if (!lectureOrigin && localStorageLecture && !isBlocked) {
      // 신규 등록 시 로컬스토리지에 저장된 데이터가 있는 경우
      Swal.fire({
        title: '기존에 작성하던 내용이 있습니다.\n불러오겠습니까?',
        text: '취소하면 저장된 내용이 사라집니다.',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setLectureOrigin(localStorageLecture);
        } else {
          localStorage.removeItem('lectureLocalData');
        }
      });
    }
  }, []);

  const changeTitle = (value) => {
    setTitle(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        title: value,
      }),
    );
  };
  const changeOnSale = (value) => {
    setOnSale(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        is_selling: value,
      }),
    );
  };
  const changeHost = (value) => {
    if (type === 'online') {
      setHost(value);
      localStorage.setItem(
        'lectureLocalData',
        JSON.stringify({
          ...localStorageLecture,
          host: value,
        }),
      );
    }
  };
  const changeType = (value) => {
    setType(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        listen_type: value,
      }),
    );
  };
  const changeTutorIds = (value) => {
    setTutorIds(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        tutorIds: value,
      }),
    );
  };
  const changeTutorRates = (value) => {
    setTutorRates(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        tutorRates: value,
      }),
    );
  };
  const changeCategoryIds = (value) => {
    setCategoryIds(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        categoryIds: value,
      }),
    );
  };
  const changeAutoCertificate = (value) => {
    setAutoCertificate(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        auto_certificate: value,
      }),
    );
  };
  const changeUseSurveyGuide = (value) => {
    setUseSurveyGuide(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        use_survey_guide: value,
      }),
    );
  };
  const changeImage = (value) => {
    setImage(value);
    // TODO: 로컬스토리지에 이미지 임시 저장할 수 있는 방법 확인 필요
    // localStorage.setItem('lectureLocalData', JSON.stringify({
    //   ...localStorageLecture,
    //   image_thumb: JSON.stringify(value),
    // }));
  };
  const changeOriginalPrice = (value) => {
    setOriginalPrice(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        price_original: value,
      }),
    );
  };
  const changeDiscountedPrice = (value) => {
    setDiscountedPrice(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        price_discounted: value,
      }),
    );
  };
  const changeCertProgressLimit = (value) => {
    setCertProgressLimit(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        pass_rate: value,
      }),
    );
  };
  const changeDescription = (value) => {
    setDescription(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        description: value,
      }),
    );
  };
  const changeIntroduction = (value) => {
    if (value !== '<p><br></p>') {
      setIntroduction(value);
      localStorage.setItem(
        'lectureLocalData',
        JSON.stringify({
          ...localStorageLecture,
          class_introduction: value,
        }),
      );
    }
  };

  const changeTutorCompanyText = (value) => {
    setTutorCompanyText(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        tutor_company_text: value,
      }),
    );
  };

  const changeTerms = (value) => {
    setTerms(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        terms: value,
      }),
    );
  };

  const changeRegistrationText = (value) => {
    setRegistrationText(value);
    localStorage.setItem(
      'lectureLocalData',
      JSON.stringify({
        ...localStorageLecture,
        registration_text: value,
      }),
    );
  };

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (isBlocked && action === 'POP') {
        const goToBack = window.confirm('강의를 저장하지 않고 나가시겠습니까?');
        if (goToBack) {
          unblock();
          return true;
        } else {
          window.history.pushState(
            {},
            '',
            Boolean(state?.lecture) ? `/lecture/single/new/${state.lecture.id}` : '/lecture/single/new',
          );
          return false;
        }
      }
    });
    return () => unblock();
  }, [isBlocked]);

  useEffect(() => {
    if (!!localStorageLecture) {
      setIsBlocked(true);
    }
  }, [localStorageLecture]);

  const changeShowTimer = useCallback(
    (value) => {
      setShowTimer(value);
      localStorage.setItem(
        'lectureLocalData',
        JSON.stringify({
          ...localStorageLecture,
          showTimer: value,
        }),
      );
    },
    [localStorageLecture],
  );

  const changeTimerText = useCallback(
    (value) => {
      setTimerText(value);
      localStorage.setItem(
        'lectureLocalData',
        JSON.stringify({
          ...localStorageLecture,
          timerText: value,
        }),
      );
    },
    [localStorageLecture],
  );

  const changeAdditive = useCallback(
    (value) => {
      // console.log(value);
      setAdditive(value);
      localStorage.setItem(
        'lectureLocalData',
        JSON.stringify({
          ...localStorageLecture,
          additive: value,
        }),
      );
    },
    [localStorageLecture],
  );

  return (
    <div className="single">
      {!editing && (
        <Field.Base title="기존 강의 내용 불러오기">
          <Autocomplete
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `[${option.id}] ${option.title}`}
            options={lectureList}
            loading={loading}
            size="small"
            sx={{ width: '30rem' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="강의명"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
                variant="outlined"
              />
            )}
            onChange={(e, value) => {
              value &&
                request.lectureDetail(lectureType, value.id).then((data) => {
                  setLectureOrigin(
                    new Model.Lecture({
                      ...data,
                      TransferReview_Logs: [],
                      ClassOnlineSchedule: null,
                      ClassOfflineSchedules: [],
                      ClassOnlineScheduleByRounds: [],
                      ClassConsultingSchedules: [],
                    }),
                  );
                });
            }}
          />
        </Field.Base>
      )}
      <Field.Input title="강의명" value={title} onChange={changeTitle} required />
      <Field.Radio
        title="강의 판매여부"
        name="onSale"
        value={onSale}
        onChange={changeOnSale}
        options={[
          { value: true, label: '판매' },
          { value: false, label: '판매불가' },
        ]}
        required
      />
      <Field.Radio
        title="강의 제공사"
        name="host"
        value={host}
        onChange={changeHost}
        options={hostOptions}
        disabled={type !== 'online'}
        required
      />
      <Field.Radio
        title="강의구분"
        name="type"
        value={type}
        onChange={changeType}
        options={typeOptions}
        disabled={editing}
        required
      />
      <Field.Radio
        title="자동 수료증 발급"
        name="autoCertificate"
        value={autoCertificate}
        onChange={changeAutoCertificate}
        options={[
          { value: true, label: '발급' },
          { value: false, label: '미발급' },
        ]}
        required
      />
      {/* 설문 유도 기능 여부 */}
      {host === 'gov' && type === 'offline' && (
        <Field.Radio
          title="사전 설문 유도 기능"
          tooltip={`사전 설문이 진행되는 경우, 신청서 제출 후 자동으로 사전 설문 페이지로 이동됩니다. 해당 페이지에서 나갈 경우 알림톡을 통해 설문 참여 독려 메시지가 발송됩니다. — 발송된 설문 링크 클릭 시 별도 로그인 없이 바로 참여 가능합니다.`}
          name="surveyGuide"
          value={useSurveyGuide}
          onChange={changeUseSurveyGuide}
          options={[
            { value: true, label: '사용함' },
            { value: false, label: '사용안함' },
          ]}
          required
        />
      )}
      <Field.Tutors
        title="강사명"
        value={tutorIds}
        onChange={changeTutorIds}
        rates={tutorRates}
        onRateChange={changeTutorRates}
        onChangeSelectedTutors={setSelectedTutors}
        required
      />
      {exposeCompanyTutors.length >= 2 && (
        <Field.Input
          title="강사 경력 문구"
          disabled={selectedTutors.length <= 1}
          value={tutorCompanyText}
          onChange={changeTutorCompanyText}
        />
      )}
      <Field.Categories
        title="강의 카테고리 구분"
        value={categoryIds}
        onChange={changeCategoryIds}
        disabled={host !== 'ours'}
      />
      <Field.Image title="강의 이미지 (상세페이지)" value={image} onChange={changeImage} required />
      <Field.Input title="수강생 가산값" value={additive} onChange={changeAdditive} type="number" />
      <Field.Input
        className="field--long-input"
        title="링크 연결(결제/신청 버튼)"
        value={externalLink}
        onChange={setExternalLink}
      />
      <Field.Base title="수강료" required>
        <div>
          <Field.Input
            type="number"
            title="정가"
            textAfter={usesUSD ? '$' : '원'}
            value={originalPrice}
            onChange={changeOriginalPrice}
          />
          <Field.Input
            type="number"
            title="할인율"
            textAfter="%"
            value={discountPercent ? Math.round(discountPercent) : 0}
            fixed
          />
          <Field.Period
            title="할인율 적용기간(선택)"
            value={{
              startDate: discountStartDate,
              startTime: discountStartTime,
              endDate: discountEndDate,
              endTime: discountEndTime,
            }}
            onChange={({ startDate, startTime, endDate, endTime }) => {
              setDiscountStartDate(startDate);
              setDiscountStartTime(startTime);
              setDiscountEndDate(endDate);
              setDiscountEndTime(endTime);
              localStorage.setItem(
                'lectureLocalData',
                JSON.stringify({
                  ...localStorageLecture,
                  discount_start: startDate,
                  discount_start_time: startTime,
                  discount_end: endDate,
                  discount_end_time: endTime,
                }),
              );
            }}
            showTime={true}
          />
          <Field.Input
            type="number"
            title={usesUSD ? '판매가(1$ 단위)' : '판매가(100원 단위)'}
            textAfter={usesUSD ? '$' : '원'}
            value={discountedPrice}
            onChange={changeDiscountedPrice}
          />
          {!!(discountedPrice % 100) && !usesUSD && (
            <span
              style={{
                color: 'red',
              }}
            >
              * 100원 단위로 입력해주세요.
            </span>
          )}
        </div>
      </Field.Base>

      {!LECTURE.ROUND_LISTEN_TYPES.includes(type) && (
        <>
          <Field.Base title="타이머 설정">
            <div>
              <Field.Radio
                title="타이머 사용여부"
                name="timer"
                value={showTimer}
                onChange={changeShowTimer}
                options={[
                  { value: true, label: '사용함' },
                  { value: false, label: '사용안함' },
                ]}
              />
              <Field.Input title="타이머 강조문구" value={timerText} onChange={changeTimerText} />
            </div>
          </Field.Base>
          <hr style={{ margin: '2rem 0' }} />
        </>
      )}
      {type === 'compulsory' && (!onlineOption.valid_days || !onlineOption.start_date) && (
        <span style={{ color: 'red' }}>* 법정의무교육 강의는 '수강일수'와 '수강시작일'을 입력해주세요.</span>
      )}
      {(!LECTURE.ROUND_LISTEN_TYPES.includes(type) || lectureType === 'package') && (
        <Field.OnlineOption title="온라인(일반) 옵션 선택" value={onlineOption} onChange={setOnlineOption} />
      )}
      {type === 'roundOnline' && (
        <Field.RoundOnlineOptions
          title="온라인(기수제) 옵션 선택"
          value={roundOnlineOptions}
          onChange={setRoundOnlineOptions}
          onChangeDeleteList={setDeleteOptionList}
        />
      )}
      {type === 'offline' && (
        <Field.OfflineOptions
          title="오프라인 옵션 선택"
          value={offlineOptions}
          lectureType={type}
          onChange={setOfflineOptions}
          onChangeDeleteList={setDeleteOptionList}
        />
      )}
      {type === 'consulting' && (
        <Field.OfflineOptions
          title={'컨설팅 옵션 선택'}
          value={consultingOptions}
          lectureType={type}
          onChange={setConsultingOptions}
          onChangeDeleteList={setDeleteOptionList}
        />
      )}
      <Field.Lectures
        type="transferReview"
        title="후기 이관 강의 선택"
        value={transferReviewsFromLecture}
        transferReviewLogs={transferReviewLogs}
        onChange={setTransferReviewsFromLecture}
      />
      {lectureType === 'single' && <Field.File title="강의자료 등록" value={material} onChange={setMaterial} />}
      <Field.Base title="수료기준 (진도율)">
        <Field.Input type="number" textAfter="%" value={certProgressLimit} onChange={changeCertProgressLimit} />
      </Field.Base>
      <Field.Textarea
        title={`SEO 소개글 (권장 길이: ${description ? description.length : 0} / 155자)`}
        value={description}
        onChange={changeDescription}
        style={{ resize: 'vertical', minHeight: '100px', height: 'auto' }}
      />
      <Field.RichText title="클래스 소개" value={introduction} onChange={changeIntroduction} />
      <Field.Base type="curriculum" title="커리큘럼">
        <Field.SampleVideos title="1) 샘플 강의" value={samples} onChange={setSamples} />
        {lectureType === 'single' ? (
          <Field.Chapters
            type={type}
            title="2) 본 강의"
            value={chapters}
            onChange={setChapters}
            shouldUploadImage={editing}
            editing={editing}
          />
        ) : (
          <Field.PackageLectures title="2) 본 강의" value={lectures} onChange={setLectures} />
        )}
      </Field.Base>
      {host === 'gov' && (
        <Field.Base title="강의 신청서 질문 항목" type="registrationFields">
          <Field.SelectThirdPartyQuestionFields
            fields={thirdPartyQuestionFields}
            setFields={setThirdPartyQuestionFields}
          />
        </Field.Base>
      )}
      {host === 'gov' && (
        <Field.Base title="강의 조항" type="terms">
          <Field.LectureTerms terms={terms} setTerms={changeTerms} />
        </Field.Base>
      )}
      {host === 'gov' && (
        <Field.Input
          type="text"
          title="강의 신청 정보 확인 문구"
          value={registrationText}
          onChange={changeRegistrationText}
        />
      )}
      <div className="single__buttons">
        <Button.Negative onClick={onClickRemove} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default Single;
