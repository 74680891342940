import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as request from '../../../Common/Util/Request';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Grid } from 'react-loader-spinner';
import Table from '../../../Common/Component/Table';
import { Month, SelectTutor, SelectVersion } from '../../../Common/Component/Field';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  MenuItem,
  Modal,
  Select,
  DialogActions,
  TextField,
  FormHelperText,
} from '@mui/material';
import * as sweetalert2 from 'sweetalert2';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import withReactContent from 'sweetalert2-react-content';
import _ from 'lodash';

function Settlement() {
  const intl = useIntl();
  const SwalWithReactContent = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(true); // 데이터 로드 여부
  const now = dayjs().format('YYYY-MM'); // 현재 월
  const [targetMonth, setTargetMonth] = useState(dayjs(now).subtract(1, 'month').format('YYYY-MM')); // 정산할 월
  const [allOrganized, setAllOrganized] = useState({ list: [] }); // 전체 정리된 데이터
  const [allHistories, setAllHistories] = useState({ list: [] }); // 전체 결제 내역
  const [organized, setOrganized] = useState({ list: [] }); // 정리된 데이터
  const [histories, setHistories] = useState({ list: [] }); // 결제 내역
  const [sources, setSources] = useState({ list: [] }); // 원본 데이터
  const [tutors, setTutors] = useState({ list: [] }); // 강사 목록
  const [targetTutor, setTargetTutor] = useState(''); // 선택된 강사
  const [searchPeriod, setSearchPeriod] = useState(''); // 검색 기간

  const [totalSalesCount, setTotalSalesCount] = useState(0); // 전체 판매 건수
  const [totalSales, setTotalSales] = useState(0); // 전체 판매 금액
  const [totalRefund, setTotalRefund] = useState(0); // 전체 환불 금액
  const [totalCommission, setTotalCommission] = useState(0); // 전체 정산 금액

  const [allData, setAllData] = useState({}); // 전체 데이터

  const storedDatetime = useMemo(() => allData.storedDatetime, [allData]);
  const listenTypes = useMemo(() => {
    return {
      online: '온라인',
      offline: '오프라인',
      roundOnline: '온라인\n(기수제)',
      consulting: '컨설팅',
      foreigner: '외국인',
    };
  }, []);

  const [versions, setVersions] = useState([]); // 버전 목록
  const [versionId, setVersionId] = useState(-1); // 선택된 버전
  const hasVersions = useMemo(() => versions.length > 0, [versions]);

  const [openManualInput, setOpenManualInput] = useState(false);
  const [allTutors, setAllTutors] = useState([]);
  const initManualData = {
    tutors: {
      list: [],
      count: 0,
    },
    organized: {
      list: [],
      count: 0,
      calculated: {
        commission: 0,
      },
    },
  };
  const [allManualData, setAllManualData] = useState(initManualData); // 수동 입력된 전체 정리된 데이터
  const [manualData, setManualData] = useState(initManualData);

  const initFormData = {
    tutorId: 0, // 강사 아이디
    listenType: '', // 강의 구분 (수기 입력)
    title: '', // 강의명
    lectureTime: '', // 강의 시간 (시수)
    lectureTimePrice: '', // 시간 당 강사료
    commission: 0, // 정산 금액 (자동 계산)
  };
  const [formData, setFormData] = useState(initFormData);

  const [errors, setErrors] = useState({
    tutorId: false,
    listenType: false,
    title: false,
    lectureTime: false,
    lectureTimePrice: false,
    commission: false,
  });
  const [openManualEditId, setOpenManualEditId] = useState(null);
  const openManualEdit = useMemo(() => openManualEditId !== null, [openManualEditId]);

  useEffect(() => {
    setIsLoading(true);
    setVersionId(-1);
    setAllManualData(initManualData);
    setManualData(initManualData);

    // First: 스냅샷이 있는지 확인
    const fetchVersions = async (month) => await request.settlementVersions({ month });
    fetchVersions(targetMonth)
      .then((list) => {
        // get max version
        setVersions(list || []);
        if (list.length > 0) {
          const maxVersion = Math.max(...list.map((item) => item.id));
          setVersionId(+maxVersion);
        } else {
          setVersionId(0);
        }
      })
      .catch((e) => {
        console.error(e);
      });

    request.tutors({ page: 1, limit: 99999 }).then(({ tutors }) => {
      setAllTutors(tutors.rows);
    });
  }, [targetMonth]);

  const setTempIdToManualData = useCallback((manualData) => {
    let tempId = 1;
    const newManualData = _.cloneDeep(manualData);
    newManualData.organized.list.forEach((item) => {
      item.id = tempId;
      tempId++;
    });

    return newManualData;
  }, []);

  useEffect(() => {
    if (versionId < 0) return;
    // Second: 데이터를 가져옴
    // hasVersions ? fetchSnapshot() : fetchAllData();
    const fetchData = async (versionId, month) => await request.settlement({ versionId, month });
    fetchData(versionId, targetMonth).then((data) => {
      setTargetTutor('');
      setOrganized({ list: [] });
      setHistories({ list: [] });
      setManualData(initManualData);
      setSearchPeriod('');

      const {
        settlementDate,
        list: { histories, organized, tutors, sources, manualData },
      } = data;
      setAllData(data);
      setSources(sources);
      setAllHistories(histories);
      setAllOrganized(organized);
      setTutors(tutors);
      const manualDataWithId = setTempIdToManualData(manualData);
      setAllManualData(manualDataWithId || initManualData);
      setSearchPeriod(
        `${dayjs(settlementDate.startDate).format('YYYY-MM-DD')} ~ ${dayjs(settlementDate.endDate).format(
          'YYYY-MM-DD',
        )}`,
      );
    });
  }, [versionId]);

  useEffect(() => {
    setIsLoading(true);
    if (targetTutor) {
      setTimeout(() => {
        const historyList = allHistories.list.filter((item) => +item.tutorId === +targetTutor);
        const organizedList = allOrganized.list.filter((item) => +item.tutorId === +targetTutor);
        setHistories({
          count: historyList.length,
          list: historyList,
        });
        setOrganized({
          count: organizedList.length,
          list: organizedList,
          calculated: {
            amount: organizedList.reduce((acc, cur) => acc + cur.amount, 0),
            currentCancelAmount: organizedList.reduce((acc, cur) => acc + cur.currentCancelAmount, 0),
            commission: organizedList.reduce((acc, cur) => acc + cur.commission, 0),
          },
        });
        setManualData({
          ...allManualData,
          organized: {
            list: allManualData.organized.list.filter((item) => item.tutorId === +targetTutor),
            calculated: {
              commission: allManualData.organized.list
                .filter((item) => item.tutorId === +targetTutor)
                .reduce((acc, cur) => acc + cur.commission, 0),
            },
          },
        });
      }, 300);
    } else {
      setHistories(allHistories);
      setOrganized(allOrganized);
      setManualData(allManualData);
    }
  }, [targetTutor, allHistories, allOrganized]);

  useEffect(() => {
    // 수동 입력된 강사 목록 추가 (기존 강사 목록에 없는 경우)
    const additionalTutorList = [];

    allManualData.tutors.list.forEach((tutor) => {
      if (!tutors.list.some((item) => item.id === tutor.id)) {
        additionalTutorList.push(tutor);
      }
    });

    setTutors((prev) => ({
      count: prev.count + additionalTutorList.length,
      list: [...prev.list, ...additionalTutorList],
    }));

    if (targetTutor) {
      setManualData({
        ...allManualData,
        organized: {
          list: allManualData.organized.list.filter((item) => item.tutorId === +targetTutor),
          calculated: {
            commission: allManualData.organized.list
              .filter((item) => item.tutorId === +targetTutor)
              .reduce((acc, cur) => acc + cur.commission, 0),
          },
        },
      });
    } else {
      setManualData(allManualData);
    }
  }, [allManualData]);

  useEffect(() => {
    if (organized?.list?.length > 0 || manualData?.organized?.list?.length > 0) {
      setTotalSales(organized.calculated?.amount || organized.list.reduce((acc, cur) => acc + cur.amount, 0));
      setTotalRefund(
        organized.calculated?.currentCancelAmount ||
          organized.list.reduce((acc, cur) => acc + cur.currentCancelAmount, 0),
      );
      setTotalCommission(
        (organized.calculated?.commission || organized.list.reduce((acc, cur) => acc + cur.commission, 0)) +
          manualData.organized.calculated.commission,
      );
      setTotalSalesCount(
        histories.count +
          (organized.list.filter((item) => item.isManual).reduce((acc, cur) => acc + cur.count, 0) || 0),
      );
      setIsLoading(false);
    } else if (
      organized?.list?.length === 0 &&
      histories?.list?.length === 0 &&
      manualData?.organized?.list?.length === 0
    ) {
      /** 정산할 내역이 없을 경우 */
      setTotalSales(0);
      setTotalRefund(0);
      setTotalCommission(0);
      setTotalSalesCount(0);
      setIsLoading(false);
    }
  }, [organized, histories, manualData]);

  const takeSnapshot = useCallback(async () => {
    // 얼럿에서 확인을 누르면 정산하기
    // 버튼명 : 취소, 진행
    sweetalert2
      .fire({
        title: `${dayjs(targetMonth).format('YYYY년 MM월')} 정산을 확정하시겠습니까?`,
        text: '정산을 확정하면 현재 시점의 정산데이터 스냅샷이 생성됩니다.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: intl.formatMessage({ id: 'CONFIRM' }),
        cancelButtonText: intl.formatMessage({ id: 'CANCEL' }),
      })
      .then((result) => {
        if (result?.isConfirmed) {
          // 정산 시작
          const data = {
            calculationYear: dayjs(now).format('YYYY'),
            calculationMonth: dayjs(now).format('MM'),
            calculationPeriodYear: dayjs(targetMonth).format('YYYY'),
            calculationPeriodMonth: dayjs(targetMonth).format('MM'),
            detail: {
              version: versions.length + 1,
              amountSales: totalSales,
              amountRefund: totalRefund,
              amountCommission: totalCommission,
              allManualData,
            },
          };

          request
            .postSettlement(data)
            .then((res) => {
              if (res.id && res.detailId) {
                sweetalert2
                  .fire({
                    title: '정산이 완료되었습니다.',
                    icon: 'success',
                    timer: 2500,
                    showConfirmButton: false,
                  })
                  .then(() => {
                    setAllData((prev) => ({
                      ...prev,
                      storedDatetime: res.storedDatetime,
                    }));
                    setVersions((prev) => [
                      { id: res.detailId, name: `Version ${prev.length + 1} (${res.storedDatetime})` },
                      ...prev,
                    ]);
                    setVersionId(res.detailId);
                    setAllManualData(initManualData);
                    setManualData(initManualData);
                  });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
  }, [targetMonth, totalSales, totalRefund, totalCommission, versions, manualData, allManualData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    let newErrors = { ...errors };
    const requiredFields = ['tutorId', 'listenType', 'title', 'lectureTime', 'lectureTimePrice', 'commission'];
    if (requiredFields.includes(name)) {
      if (!value) {
        newErrors = { ...newErrors, [name]: '필수 입력 항목입니다.' };
      } else {
        newErrors = { ...newErrors, [name]: false };
      }
    }
    const numberTypes = ['lectureTime', 'lectureTimePrice', 'commission'];
    if (numberTypes.includes(name)) {
      if (!/^-?\d*\.?\d*$/.test(value)) {
        newErrors = { ...newErrors, [name]: '숫자만 입력 가능합니다.' };
      } else {
        newErrors = { ...newErrors, [name]: false };
      }
    }
    if (name === 'lectureTime' || name === 'lectureTimePrice') {
      if (parseFloat(value) < 0) {
        newErrors = { ...newErrors, [name]: '0 이상의 숫자만 입력 가능합니다.' };
      }
    }

    const lectureTime = parseFloat(updatedFormData.lectureTime || 0);
    const lectureTimePrice = parseFloat(updatedFormData.lectureTimePrice || 0);

    // 정산 금액 계산 (시간당 강사료 * 강의 시간)
    const calculatedCommission = lectureTime * lectureTimePrice;
    updatedFormData.commission = isNaN(calculatedCommission) ? '' : calculatedCommission.toFixed(0); // 소수점 이하 반올림
    if (updatedFormData.commission !== null && updatedFormData.commission !== undefined) {
      newErrors = { ...newErrors, commission: false };
    }

    setFormData({ ...updatedFormData });
    setErrors(newErrors);
  };

  const handleCancel = () => {
    if (openManualEditId !== null) {
      setOpenManualEditId(null);
    }
    setOpenManualInput(false);
    setFormData(initFormData);
    setErrors({
      tutorId: false,
      listenType: false,
      title: false,
      lectureTime: false,
      lectureTimePrice: false,
      commission: false,
    });
  };

  const [notifying, setNotifying] = useState(false);
  const [selectedTutors, setSelectedTutors] = useState([]);
  const [checkTarketModalOpen, setCheckTarketModalOpen] = useState(false);
  const targetTutors = useMemo(() => tutors.list, [tutors]);

  const notify = useCallback(() => {
    const targetTutors = selectedTutors.map((tutor) => tutor.id);
    setNotifying(true);
    request
      .notifySettlement({ targetTutors, targetMonth })
      .then((res) => {
        setNotifying(false);
        if (res.success) {
          Swal.fire({
            title: '알림이 전송되었습니다.',
            icon: 'success',
            html:
              `<ul>
                    <li style="margin-bottom: 0.25rem">알림대상: ${
                      res.sendTutorList.length + res.failedTutorList.length
                    }명</li>
                    <li style="margin-bottom: 0.25rem">발송성공: ${res.sendTutorList.length}명</li>` +
              (res.failedTutorList?.length > 0
                ? `<li style="margin-bottom: 0.75rem">발송실패: ${res.failedTutorList.length}명</li>
                    <li style="margin-bottom: 0.25rem">실패목록:<br/>${res.failedTutorList
                      .map((item) => item.name)
                      .join(', ')}</li>
                    <li style="margin-bottom: 0.25rem">실패사유:<br/>강사분들의 이메일이 등록되어 있지 않습니다.</li>`
                : '') +
              `</ul>`,
          }).then(() => {});
        }
      })
      .catch((err) => {
        setNotifying(false);
        console.error(err);
        Swal.fire({
          title: '알림 전송에 실패하였습니다.',
          icon: 'error',
          timer: 2500,
          showConfirmButton: false,
        }).then(() => {});
      })
      .finally(() => {
        setCheckTarketModalOpen(false);
        setSelectedTutors([]);
      });
  }, [targetMonth, selectedTutors]);

  const [selectLectureTitle, setSelectLectureTitle] = useState('all');

  useEffect(() => {
    setSelectLectureTitle('all');
  }, [
    organized,
    histories,
    sources,
    tutors,
    targetTutor,
    totalSalesCount,
    totalSales,
    totalRefund,
    totalCommission,
    allData,
    versionId,
  ]);
  console.log('allManualData.organized.list', allManualData.organized.list);
  return (
    <>
      <div id="settlement">
        <div id="settlement-header">
          <div id="settlement-form-wrap">
            <div id="search-date-wrap">
              <Month
                value={targetMonth}
                onChange={setTargetMonth}
                title={intl.formatMessage({ id: 'SETTLEMENT_MONTH', defaultMessage: '정산 월' })}
                max={dayjs(now).format('YYYY-MM')}
              />
              {/*<span className="search-period">({searchPeriod})</span>*/}
            </div>
            <div id="search-tutor-wrap">
              <SelectTutor
                title={`강사(${tutors.count}명)`}
                value={targetTutor}
                onChange={setTargetTutor}
                options={tutors.list}
              />
            </div>
            <div id="settlement-summary-wrap">
              <strong className="sales">
                {intl.formatMessage(
                  { id: 'SALES', defaultMessage: '판매: {toLocaleString}원' },
                  { toLocaleString: totalSales.toLocaleString() },
                )}
              </strong>
              <strong className="refund">
                {intl.formatMessage(
                  { id: 'REFUND', defaultMessage: '환불: {toLocaleString}원' },
                  { toLocaleString: totalRefund.toLocaleString() },
                )}
              </strong>
              <strong className="commission">
                {intl.formatMessage(
                  { id: 'COMMISSION' },
                  {
                    toLocaleString: `${totalCommission.toLocaleString()}원 (수동: ${manualData.organized.calculated.commission.toLocaleString()}원)`,
                  },
                )}
              </strong>
            </div>
          </div>
          <div id="settlement-btn-wrap">
            {versions && versions.length > 0 && (
              <SelectVersion
                value={versionId}
                options={versions}
                onChange={(value) => {
                  setIsLoading(true);
                  // 버전 아이디 변경
                  setVersionId(+value);
                }}
              />
            )}
            <Button
              variant="contained"
              type="primary"
              onClick={takeSnapshot}
              disabled={isLoading || +versionId !== 0}
              disableFocusRipple
              size="small"
            >
              {intl.formatMessage({ id: 'EXECUTE_SETTLEMENT', defaultMessage: '정산하기' })}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setCheckTarketModalOpen(true);
              }}
              disabled={isLoading || +versionId === 0}
              disableFocusRipple
              size="small"
            >
              알림보내기
            </Button>
            <Dialog
              open={checkTarketModalOpen}
              onClose={(e, reason) =>
                reason !== 'backdropClick' && reason !== 'escapeKeyDown' && setCheckTarketModalOpen(false)
              }
            >
              <DialogTitle>알림 받을 강사를 선택해주세요.</DialogTitle>
              <DialogContent>
                <List
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    gap: '0.5rem',
                    '& > li': {
                      padding: '0',
                      display: 'flex',
                      width: 'fit-content',
                      alignItems: 'center',
                    },
                  }}
                >
                  <ListItem>
                    <Checkbox
                      checked={selectedTutors.length === targetTutors.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTutors(targetTutors);
                        } else {
                          setSelectedTutors([]);
                        }
                      }}
                      size="small"
                      style={{ padding: '0.125rem 0 0.1875rem', marginRight: '0.5rem' }}
                    />
                    전체선택
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    gap: '0.5rem',
                    '& > li': {
                      padding: '0',
                      display: 'flex',
                      width: '30%',
                      alignItems: 'center',
                    },
                  }}
                >
                  {targetTutors.map((item) => {
                    return (
                      <ListItem key={item.id}>
                        <Checkbox
                          checked={selectedTutors.some((tutor) => tutor.id === item.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedTutors((prev) => [...prev, item]);
                            } else {
                              setSelectedTutors((prev) => prev.filter((tutor) => tutor.id !== item.id));
                            }
                          }}
                          size="small"
                          style={{ padding: '0.125rem 0 0.1875rem', marginRight: '0.5rem' }}
                        />
                        {item.name}
                      </ListItem>
                    );
                  })}
                </List>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      setCheckTarketModalOpen(false);
                      setSelectedTutors([]);
                    }}
                    disableFocusRipple
                    size="small"
                  >
                    취소
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCheckTarketModalOpen(false);
                      notify();
                    }}
                    disableFocusRipple
                    size="small"
                    style={{ marginLeft: '0.5rem' }}
                    disabled={selectedTutors.length === 0}
                  >
                    확인
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div id="settlement-body">
          <div className="loading">
            <Grid type="ThreeDots" color="#00BFFF" height={40} width={40} />
          </div>
          <div
            id="settlement-body-header"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <Button
              color={versionId !== 0 ? 'error' : 'primary'}
              variant={versionId !== 0 ? 'outlined' : 'contained'}
              onClick={() => {
                if (versionId !== 0) {
                  Swal.fire({
                    title: '우측 상단에서 새 버전으로 설정하고, 다시 시도해주세요.',
                    icon: 'warning',
                    showConfirmButton: true,
                  }).then(() => {});
                  return;
                }

                setOpenManualInput(true);
              }}
            >
              수동 입력
            </Button>

            <Select
              value={selectLectureTitle}
              size="small"
              sx={{
                width: '100%',
                maxWidth: '37rem',
                height: '2rem',
                fontSize: '0.875rem',
              }}
            >
              <MenuItem value="all" onClick={() => setSelectLectureTitle('all')} sx={{ fontSize: '0.875rem' }}>
                전체
              </MenuItem>
              {organized?.list?.map((item, idx) => (
                <MenuItem
                  key={idx}
                  value={item.title}
                  onClick={() => setSelectLectureTitle(item.title)}
                  sx={{ fontSize: '0.875rem' }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div id="tables-wrap">
            <div id="organized-table">
              {organized.list.length > 0 && (
                <Table
                  rowClick={(row) => {
                    setSelectLectureTitle(row.original.title);
                  }}
                  columns={[
                    {
                      Header: '구분',
                      accessor: 'listen_type',
                      Cell: ({ value }) =>
                        value ? (
                          <span className="nowrap">
                            {intl
                              .formatMessage({
                                id: `ID_LISTEN_TYPE_${value.toUpperCase()}`,
                              })
                              .replace('(일반)', '')
                              .replace('(기수', '\n(기수')}
                          </span>
                        ) : (
                          ''
                        ),
                    },
                    {
                      Header: '강의명',
                      accessor: 'title',
                      Cell: ({ value }) => (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          onClick={() => {
                            setSelectLectureTitle(value);
                          }}
                          onDoubleClick={() => {
                            setSelectLectureTitle('all');
                          }}
                        >
                          {value}
                        </div>
                      ),
                    },
                    {
                      Header: '거래 수',
                      accessor: 'count',
                      Cell: ({ value }) => <span>{value?.toLocaleString()}</span>,
                    },
                    {
                      Header: '결제 금액',
                      accessor: 'amount',
                      Cell: ({ value }) => <span className="amount">{value ? value.toLocaleString() : ''}</span>,
                    },
                    {
                      Header: '환불 금액',
                      accessor: 'currentCancelAmount',
                      Cell: ({ value }) => <span className="amount refund">{value ? value.toLocaleString() : ''}</span>,
                    },
                    {
                      Header: '수수료',
                      accessor: 'feeRate',
                      Cell: ({ value }) => <span>{value ? `${value.toLocaleString()}%` : ''}</span>,
                    },
                    {
                      Header: '정산율',
                      accessor: 'commissionRate',
                      Cell: ({ value }) => <span>{value ? `${value.toLocaleString()}%` : ''}</span>,
                    },
                    {
                      Header: '정산 금액',
                      accessor: 'commission',
                      Cell: ({ value }) => <span className="amount commission">{value?.toLocaleString()}</span>,
                    },
                  ]}
                  data={[
                    ...organized.list,
                    {},
                    {
                      title: '합계',
                      count: totalSalesCount,
                      amount: totalSales,
                      currentCancelAmount: totalRefund,
                      commission: organized.calculated?.commission,
                    },
                  ]}
                  fetchData={() => {}} // 필요없는 함수
                />
              )}

              {manualData.organized.list.length > 0 && (
                <Table
                  onClickRow={(row) => {
                    if (versionId !== 0) {
                      Swal.fire({
                        title: '우측 상단에서 새 버전으로 설정하고, 다시 시도해주세요.',
                        icon: 'warning',
                        showConfirmButton: true,
                      }).then(() => {});
                      return;
                    } else if (!!row.original.tutorId) {
                      console.log('row: ', row);
                      setOpenManualEditId(row.original.id);
                      setFormData({
                        tutorId: row.original.tutorId,
                        listenType: row.original.listen_type,
                        title: row.original.title,
                        lectureTime: row.original.lectureTime,
                        lectureTimePrice: row.original.lectureTimePrice,
                        commission: row.original.commission,
                      });
                    }
                  }}
                  columns={[
                    {
                      Header: '구분',
                      accessor: 'listen_type',
                      Cell: ({ value }) => (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {value}
                        </div>
                      ),
                    },
                    {
                      Header: '강의명',
                      accessor: 'title',
                      Cell: ({ value }) => (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {value}
                        </div>
                      ),
                    },
                    {
                      Header: '시수',
                      accessor: 'lectureTime',
                      Cell: ({ value }) => <span>{value?.toLocaleString()}</span>,
                    },
                    {
                      Header: '시간 당 강사료',
                      accessor: 'lectureTimePrice',
                      Cell: ({ value }) => <span className="amount">{value ? value.toLocaleString() : ''}</span>,
                    },
                    {
                      Header: '정산 금액',
                      accessor: 'commission',
                      Cell: ({ value }) => <span className="amount commission">{value?.toLocaleString()}</span>,
                    },
                  ]}
                  data={[
                    ...manualData.organized.list,
                    {},
                    {
                      title: '합계',
                      commission: manualData.organized.calculated.commission,
                    },
                  ]}
                  fetchData={() => {}} // 필요없는 함수
                />
              )}
            </div>

            <div id="history-table">
              <Table
                columns={[
                  { Header: 'No', accessor: '', Cell: ({ row }) => <span>{row.index + 1}</span> },
                  { Header: '강의명', accessor: 'title' },
                  {
                    Header: '구분',
                    accessor: 'listen_type',
                    Cell: ({ value }) => <span className="nowrap">{listenTypes[value] || ''}</span>,
                  },
                  {
                    Header: '결제일',
                    accessor: 'date',
                    Cell: ({ value }) => <span className="paymentDate">{value}</span>,
                  },
                  {
                    Header: '거래 금액',
                    accessor: 'amount',
                    Cell: ({ value }) =>
                      value < 0 ? (
                        <span className="amount refund">{value.toLocaleString()}</span>
                      ) : (
                        <span className="amount">{value.toLocaleString()}</span>
                      ),
                  },
                ]}
                data={[
                  ...histories.list.filter((item) => selectLectureTitle === 'all' || item.title === selectLectureTitle),
                ]}
                fetchData={() => {}} // 필요없는 함수
              />
            </div>
          </div>
        </div>

        <style jsx="true">{`
          #settlement {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;
          }

          #settlement-header,
          #settlement-header #settlement-form-wrap,
          #settlement-header #settlement-btn-wrap {
            display: flex;
            column-gap: 2rem;
            row-gap: 1rem;
            align-items: center;
            font-size: 0.875rem;
            min-height: 3rem;
          }

          #settlement-header #settlement-btn-wrap {
            column-gap: 0.5rem;
          }

          #settlement-header {
            justify-content: space-between;
          }

          #settlement-header .field__title {
            width: fit-content;
          }

          #settlement-header #search-date-wrap,
          #settlement-header #settlement-summary-wrap {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            flwx-wrap: wrap;
          }

          #settlement-header #settlement-summary-wrap .sales {
            color: #3333ff;
          }

          #settlement-header #settlement-summary-wrap .refund {
            color: #ff0000;
          }

          #settlement-header #settlement-summary-wrap .commission {
            color: #8a2be2;
          }

          #settlement-header #search-tutor-wrap .field__body {
            min-width: 10rem;
          }

          #settlement-body {
            width: 100%;
            height: 100%;
            flex: 3 2 auto;
            position: relative;
            display: flex;
            flex-direction: column;
          }

          #settlement-body .loading {
            position: absolute;
            width: 100%;
            mih-height: 100vh;
            height: 100%;
            background-color: #ffffff;
            z-index: 100;
            transition: opacity 0.3s ease-in-out;
            pointer-events: none;
            opacity: ${isLoading ? 1 : 0};
          }

          #settlement-body .loading > div {
            position: absolute;
            left: 50%;
            top: 25rem;
          }

          #tables-wrap {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: 1.5rem;
            font-size: 0.875rem;
          }

          #tables-wrap #organized-table {
            width: 60%;
          }

          #tables-wrap #organized-table tr:last-child {
            font-weight: bold;
          }

          #tables-wrap #history-table {
            width: 40%;
          }

          #tables-wrap #history-table .paymentDate,
          #tables-wrap th,
          .nowrap {
            white-space: nowrap;
          }

          #tables-wrap td {
            font-size: 0.75rem;
            padding: 0.5rem 0.25rem;
            height: 2rem;
            white-space: pre-wrap;
          }

          #tables-wrap td .amount {
            float: right;
            padding-right: 0.25rem;
            color: #3333ff;
          }

          #tables-wrap td .amount.refund {
            color: #ff0000;
          }

          #tables-wrap td .amount.commission {
            font-weight: bold;
          }
        `}</style>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          transition: 'opacity 0.3s ease-in-out',
          select: 'none',
          opacity: notifying ? 1 : 0,
          zIndex: notifying ? 100 : -1,
        }}
      >
        <ReactLoading
          type="spinningBubbles"
          color="#000"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '5rem',
            height: '5rem',
          }}
        />
      </div>

      {/* 수동 입력 모달 */}
      <Dialog open={openManualInput || openManualEdit} onClose={handleCancel}>
        <DialogTitle>수동 입력</DialogTitle>
        <DialogContent>
          <Select
            name="tutorId"
            value={formData.tutorId}
            fullWidth
            onChange={handleChange}
            error={errors.tutorId}
            sx={{ marginTop: 2 }}
          >
            <MenuItem value={0}>강사 선택</MenuItem>
            {allTutors.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {errors.tutorId && (
            <FormHelperText error sx={{ marginTop: '3px', marginLeft: '14px', marginRight: '14px' }}>
              {errors.tutorId}
            </FormHelperText>
          )}

          <TextField
            fullWidth
            name="listenType"
            label="강의 구분"
            value={formData.listenType}
            onChange={handleChange}
            error={errors.listenType}
            helperText={errors.listenType}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            name="title"
            label="강의명"
            value={formData.title}
            onChange={handleChange}
            error={errors.title}
            helperText={errors.title}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            name="lectureTime"
            label="시수"
            value={formData.lectureTime}
            onChange={handleChange}
            error={errors.lectureTime}
            helperText={errors.lectureTime}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            name="lectureTimePrice"
            label="시간 당 강사료"
            value={formData.lectureTimePrice}
            onChange={handleChange}
            error={errors.lectureTimePrice}
            helperText={errors.lectureTimePrice}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            name="commission"
            label="정산금액"
            value={formData.commission}
            onChange={handleChange}
            error={errors.commission}
            helperText={errors.commission}
            disabled
            sx={{ marginTop: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            취소
          </Button>
          {openManualEdit && (
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                const newOrganized = _.cloneDeep(allManualData.organized);
                newOrganized.list = newOrganized.list.filter((item) => item.id !== openManualEditId);

                const removeTutorList = [];
                const combinedOrganizedList = [...allOrganized.list, ...newOrganized.list];
                tutors.list.forEach((tutor) => {
                  if (!combinedOrganizedList.some((item) => item.tutorId === tutor.id)) {
                    removeTutorList.push(tutor);
                  }
                });
                const newTutorList = tutors.list.filter(
                  (item) => !removeTutorList.some((tutor) => tutor.id === item.id),
                );

                setTutors(() => ({
                  count: newTutorList.length,
                  list: newTutorList,
                }));
                setAllManualData((prev) => ({
                  ...prev,
                  tutors: {
                    count: newTutorList.length,
                    list: newTutorList,
                  },
                  organized: {
                    count: prev.organized.count - 1,
                    calculated: {
                      commission:
                        prev.organized.calculated.commission -
                        allManualData.organized.list.find((item) => item.id === openManualEditId).commission,
                    },
                    list: newOrganized.list,
                  },
                }));
                handleCancel();
              }}
            >
              삭제
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              // 필수 입력 및 error 상태 체크
              const requiredFields = [
                'tutorId',
                'listenType',
                'title',
                'lectureTime',
                'lectureTimePrice',
                'commission',
              ];
              let newErrors = { ...errors };
              let hasError = Object.values(newErrors).some((error) => error);

              requiredFields.forEach((field) => {
                if (!formData[field]) {
                  newErrors[field] = '필수 입력 항목입니다.';
                  hasError = true;
                }
              });

              setErrors(newErrors);

              if (hasError) return;

              // 수동 입력 데이터 처리 로직 추가
              const manualData = {
                id: Math.max(...allManualData.organized.list.map((item) => item.id), 0) + 1,
                tutorId: formData.tutorId,
                tutorName: allTutors.find((item) => item.id === formData.tutorId).name,
                listen_type: formData.listenType,
                title: formData.title,
                lectureTime: parseFloat(formData.lectureTime),
                lectureTimePrice: parseFloat(formData.lectureTimePrice),
                commission: parseFloat(formData.commission),
              };

              if (openManualEdit) {
                // 기존 데이터 수정
                const newOrganized = _.cloneDeep(allManualData.organized);
                newOrganized.list = newOrganized.list.map((item) => {
                  if (item.id === openManualEditId) {
                    return manualData;
                  }
                  return item;
                });

                const removeTutorList = [];
                const combinedOrganizedList = [...allOrganized.list, ...newOrganized.list];
                tutors.list.forEach((tutor) => {
                  if (!combinedOrganizedList.some((item) => item.tutorId === tutor.id)) {
                    removeTutorList.push(tutor);
                  }
                });
                const newTutorList = tutors.list.filter(
                  (item) => !removeTutorList.some((tutor) => tutor.id === item.id),
                );

                setTutors(() => ({
                  count: newTutorList.length,
                  list: newTutorList,
                }));
                setAllManualData((prev) => ({
                  ...prev,
                  tutors: {
                    count: newTutorList.length,
                    list: newTutorList,
                  },
                  organized: {
                    count: prev.organized.count,
                    calculated: {
                      commission:
                        prev.organized.calculated.commission -
                        prev.organized.list.find((item) => item.id === openManualEditId).commission +
                        manualData.commission,
                    },
                    list: newOrganized.list,
                  },
                }));
              } else {
                // 새로운 데이터 추가
                setAllManualData((prev) => ({
                  ...prev,
                  organized: {
                    count: prev.organized.count + 1,
                    calculated: {
                      ...prev.calculated,
                      commission: prev.organized.calculated.commission + manualData.commission,
                    },
                    list: [...prev.organized.list, manualData],
                  },
                }));
              }

              // 기존에 없는 강사일 경우 (강사 목록에 추가)
              if (!tutors.list.find((item) => item.id === manualData.tutorId)) {
                setAllManualData((prev) => ({
                  ...prev,
                  tutors: {
                    count: prev.tutors.count + 1,
                    list: [...prev.tutors.list, { id: manualData.tutorId, name: manualData.tutorName }],
                  },
                }));
              }

              // 초기화
              handleCancel();
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Settlement;
